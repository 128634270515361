/* eslint-disable no-undef */
import 'dayjs/locale/id'
import dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
dayjs.locale('id')
dayjs.extend(utc)

export const dateFullTime = (date) => {
  if (date) return dayjs.utc(date).format('DD/MMM/YYYY HH:mm:ss')
  return null
}

// Date & Time
export const dateTimeStringFullTimeDash = (date) => {
  if (date) return dayjs(date).format('DD-MM-YYYY HH:mm:ss')
  return null
}
export const dateTimeStringFullTimeDash2 = (date) => {
  if (date) return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
  return null
}
export const dateTimeStringFullTime = (date) => {
  if (date) return dayjs(date).format('DD/MMM/YYYY HH:mm:ss')
  return null
}

// utc to local
export const dateTimeStringFullTimeDashWithComma = (date) => {
  if (date) return dayjs(date).utc('z').local('id').format('DD/MM/YYYY, HH:mm')
  return null
}

export const datetimeString = (date) => {
  if (date) return dayjs(date).format('DD/MM/YYYY HH:mm')
  return null
}

export const dateTimeString = (date) => {
  if (date) return dayjs(date).subtract(7, 'hour').format('DD/MM/YYYY HH:mm')
  return null
}

export const dateNowParse = (date) => {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY')
  } else {
    return dayjs().format('DD/MM/YYYY')
  }
}

export const dateStringDashFromDay = (date) => {
  if (date) return dayjs(date).format('DD-MM-YYYY')
  return null
}

export const dateString = (date) => {
  if (date) return dayjs(date).format('YYYY-MM-DD')
  return null
}

export const dateString2 = (date) => {
  if (date) return dayjs(date).format('DD MMM YYYY')
  return null
}

export const dateStringAdd = (type, count, unit) => {
  if (type == 'min') {
    if (type) return dayjs().add(count, unit).format('YYYY-MM-DD')
    return null
  }
}

export const dateStringAddWithValue = (type, count, unit, date) => {
  if (type == 'min') {
    if (type) return dayjs(date).add(count, unit).format('YYYY-MM-DD')
    return null
  } else if (type == 'max') {
    if (type) return dayjs(date).subtract(count, unit).format('YYYY-MM-DD')
    return null
  }
}

export const customDateMonthString = (date, param = 'slash') => {
  if (param == 'slash') {
    if (date) return dayjs(date).format('DD/MMM/YYYY')
  } else if (param == 'fulltime') {
    if (date) return dayjs(date).format('DD/MMM/YYYY, HH:mm:ss')
  } else if (param == 'space') {
    if (date) return dayjs(date).format('YYYY MMM DD')
  } else if (param == 'fulltime-space') {
    if (date) return dayjs(date).locale('en').format('DD MMMM YYYY, HH:mm:ss')
  } else if (param == 'fulltime-slash') {
    if (date) return dayjs(date).format('DD/MM/YYYY, HH:mm:ss')
  } else {
    if (date) return dayjs(date).format('DD MMM YYYY')
  }
  return null
}
export const formatUTCToLocalTimeOnlyTime = (date) => {
  // 02-01-2020 17:00:00 -> 00:00
  if (date) return dayjs.utc(date).local().format('HH:mm')
  return null
}
export const formatUTCToLocalTime4 = (date) => {
  // 02-01-2020 17:00 -> 03-01-2020 00:00
  if (date) return dayjs.utc(date).local().format('DD-MM-YYYY HH:mm')
  return null
}
export const formatUTCToLocalTime3 = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm:ss')
  return null
}
export const formatUTCToLocalTime2 = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('MM/DD/YYYY HH:mm:ss')
  return null
}
export const formatUTCToLocalTime = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('DD/MM/YYYY HH:mm:ss')
  return null
}
export const formatUTCToLocalTimeCustomMonthNoTime = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('DD/MMM/YYYY')
  return null
}
export const formatUTCToLocalTimeCustomNoTimeNoFormatMonth = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('MM-DD-YYYY')
  return null
}
export const formatUTCToLocalTimeCustomMonth = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('DD/MMM/YYYY HH:mm:ss')
  return null
}
export const formatUTCToLocalTimeCustomMonthNoSecond = (date) => {
  // 02-01-2020 17:00:00 -> 03-01-2020 00:00:00
  if (date) return dayjs.utc(date).local().format('DD/MMM/YYYY HH:mm')
  return null
}
export const formatDateTimeToUTC = (date) => {
  // 02-01-2020 00:00:00 -> 01-01-2020 17:00:00
  if (date) { return dayjs.utc(dayjs(date)).format('YYYY-MM-DD HH:mm:ss') }
  return null
}
export const formatDateTimeToUTCStatic1 = (date) => {
  // 02-01-2020 00:00:00 -> 01-01-2020 17:00:00
  if (date) { return dayjs.utc(dayjs(`${date} 00:00:00`)).format('YYYY-MM-DD HH:mm:ss') }
  return null
}
export const formatDateTimeToUTCStatic2 = (date) => {
  // 02-01-2020 00:00:00 -> 01-01-2020 17:00:00
  if (date) { return dayjs.utc(dayjs(`${date} 23:59:59`)).format('YYYY-MM-DD HH:mm:ss') }
  return null
}

export const currency = (value) => {
  if (value) return `Rp ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}` //NOSONAR
}

export const currencyLocale = (value) => {
  if (value) return `Rp ${value.toLocaleString('id')}`
}

export const currencyWithoutRp = (value) => {
  if (value) return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}` //NOSONAR
}

export const currencyCommaWithoutRp = (value) => {
  if (value) return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  return value
}

export const currencyCommaWithMaxThreeDecimals = (value) => {
  if (value) {
    // Ensure the value is treated as a string, then truncate to 3 decimal places without rounding
    const stringValue = value.toString()
    const [integerPart, decimalPart] = stringValue.split('.')

    // If there's a decimal part, truncate it to the first 3 digits
    const truncatedDecimal = decimalPart ? decimalPart.slice(0, 3) : ''

    // Combine the integer part with the truncated decimal part
    const formattedValue = truncatedDecimal ? `${integerPart}.${truncatedDecimal}` : integerPart

    // Add commas for thousands separation
    return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return value
}


export const expired = (date) => {
  if (date) {
    const dates = dayjs(date).format('YYYY-MM-DD')
    if (dayjs(dates).isBefore(dayjs(new Date()).format('YYYY-MM-DD'))) {
      return true
    } else {
      return false
    }
  }
}

export const dateTimeStringFullDayTime = (date) => {
  if (date) return dayjs(date).format('dddd, DD/MMM/YYYY, HH:mm:ss')
  return null
}

export const dateMonthString = (date, param = 'slash', time = false) => {
  if (param == 'slash') {
    if (date) return dayjs(date).format('DD/MMM/YYYY')
  } else if (param == 'fulltime') {
    if (date && time) return dayjs(date).format('DD/MMM/YYYY, HH:mm:ss')
  } else if (param == 'dash') {
    if (date) return dayjs(date).format('DD-MMM-YYYY')
  } else if (param == 'no-space') {
    if (date) return dayjs(date).format('DDMMYYYY')
  } else {
    if (date) return dayjs(date).format('DD MMM YYYY')
  }
  return null
}

export const formatPercent = (value) => {
  if (value%1 === 0) {
    let val = (value/1).toFixed(0).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") //NOSONAR
  } else {
    let val = (value/1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") //NOSONAR
  }
}

export const formatPercentWithPoint = (value) => {
  if (value%1 === 0) {
    let val = (value/1).toFixed(0)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") //NOSONAR
  } else {
    let val = (value/1).toFixed(2)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") //NOSONAR
  }
}
export const dateRange = (month, year = new Date().getFullYear()) => {
  const startOfMonth = dayjs().startOf('month').month(month).year(year).format('YYYY-MM-DD')
  const endOfMonth   = dayjs().endOf('month').month(month).year(year).format('YYYY-MM-DD')

  return [startOfMonth, endOfMonth]
}
export const dateGetOnlyMonth = (date) => {
  if (date) return dayjs(date).format('MMM-YYYY')
  return null
}
export const formatPrice = (value) => {
  let val = (value/1).toFixed(0).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") //NOSONAR
}
export const dateGetOnlyTime = (date) => {
  if (date) return dayjs(date).format('HH:mm')
  return null
}
export const dateGetOnlyFullTime = (date) => {
  if (date) return dayjs(date).format('HHmmss')
  return null
}
export const formatNumber = n => {
  if (n < 1e3) return n
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K"
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M"
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B"
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T"
}
export const checkDateAndTime = (date) => {
  if (date) {
    const dateNow = dayjs(new Date()).format('YYYY-MM-DD HH:mm')
    return dayjs(dateNow).isAfter(dayjs(date).format("YYYY-MM-DD HH:mm"))
  }
  return null
}
export const checkDateMoreThanSevenDays = (dateFrom, dateTo) => {
  if (dateFrom && dateTo) {
    const date1 = dayjs(dateFrom?.split(" ")?.shift())
    const date2 = dayjs(dateTo?.split(" ")?.shift())

    if (date2.diff(date1, 'day') > 6) {
      return false
    }

    return true
  }
  return null
}
export const dateFullMonth = (date) => {
  if (date) return dayjs(date).format('DD MMMM YYYY')
  return null
}
